import React, {createContext, FC, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import axios from 'axios';

interface TimezoneContextProps {
    timezone: string|null;
    updateTimezone: (newTimezone: string) => void;
    timezoneList: string[];
}

const messageError = 'Not initialized';

const TimezoneContext = createContext<TimezoneContextProps>({
    timezone: '',
    updateTimezone: () => console.error(messageError),
    timezoneList: []
})


export const useTimezone = () => useContext(TimezoneContext);

interface TimezoneProviderProps {
    children: ReactNode;
}

const getTimezoneListe = async (): Promise<string[]> => {
  const response = await axios.get('/js/timezones.js');
  return response.data;
}

export const TimezoneProvider: FC<TimezoneProviderProps> = ({ children }) => {
    const timezoneDefault = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [timezone, setTimezone] = useState<string|null>(timezoneDefault);
    const [timezoneList, setTimezoneList] = useState<string[]>([]);

    useEffect(() => {
      getTimezoneListe()
        .then((timezones) => setTimezoneList(timezones))
        .catch(err => console.error(err))
  }, [])

    const updateTimezone = useCallback((newTimezone: string) => {
      setTimezone(newTimezone);
    }, []);
  
    return (
      <TimezoneContext.Provider 
        value={{
          timezone, 
          updateTimezone, 
          timezoneList
        }}>
        {children}
      </TimezoneContext.Provider>
    );
};
